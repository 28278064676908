import React from 'react'

const Cpr = () => {
  return (
    <div className="position-relative">
      <div className="container-fluid full-width-background-image-container">
        <div className="row h-100 background-colour-beige-4">
          <div className="col-md-6 order-md-2 background-image background-cpr"></div>
          <div className="col-md-6 order-md-1"></div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <section className="col-md-6 order-md-2">
            <div className="spacer-background-image"></div>
          </section>

          <section className="col-md-6 order-md-1 p-5">
            <h2>Our shelves are stocked with only the best.</h2>
            <p>
              CPR Vitafive is an amazing Australian owned family operated
              company, their products achieve the perfect balance of protein,
              moisture, vitamins and minerals that brings your hair to life!
            </p>
            <p>
              Their passion for beautiful hair and education has inspired me
              greatly.
            </p>
            <p>
              I am proud to support such a wonderful product in my beautiful eco
              friendly salon.
            </p>
            <a
              className="links"
              href="https://www.cprhair.com/about-us"
              target="_blank"
              rel="noreferrer"
            >
              Learn about CPR
            </a>
          </section>
        </div>
      </div>
    </div>
  )
}

export default Cpr
